/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                               Banner Section                               */
/* -------------------------------------------------------------------------- */

function ImageOnHover({ image, alternative, onMouseLeave, key }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container
      className="image-on-hover flex justify-center items-center fluid"
      key={key}
      onMouseLeave={onMouseLeave}
      fluid
    >
      <img src={image} alt={alternative} onMouseLeave={onMouseLeave} />
    </Container>
  );
}

ImageOnHover.propTypes = {
  key: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alternative: PropTypes.string.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default ImageOnHover;
