/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable react/jsx-no-bind */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
// UI lib components
import { Container, Row, Col, Visible } from 'react-grid-system';

// UI local components
import { useI18next } from 'gatsby-plugin-react-i18next';
import BannerSection from '../banner';
import ImageOnHover from '../imageZoom';

// Assets
import CLOSE_ICON from '../../../images/solutions/icons/cloase-icon.svg';
import UNEXIST_IMG from '../../../images/solutions/icons/unexist-img.svg';

// Styles
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function SingleSolution({
  data,
  getLevel2,
  location,
  getSolutionLevel1,
  getSolutionLevel2,
  getSolutionLevel3,
}) {
  /* -------------------------------- HOOKS --------------------------------- */
  const [openForm, setOpenForm] = useState({
    id: '',
    isOpen: false,
  });
  const [hoverImg, setHoverImg] = useState({
    id: '',
    isHovered: false,
  });

  /* ****************************** CONSTANTS ******************************* */
  const { language } = useI18next();
  const API_LINK = 'https://admin.luxor.com.tn';

  /* ********************************* HOOKS ********************************** */
  const [showPageContent, setPageContent] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPageContent(true);
    }, 300);
  }, []);

  //  localization
  const { t } = useTranslation();
  const { solutionInput1, solutionInput2, btn1, btn2, btn3 } = t('form', {
    returnObjects: true,
  });

  const { titleLinkSol1, titleLinkSol2 } = t('solution', {
    returnObjects: true,
  });

  /* ****************************** RENDER HELPERS **************************** */
  function getSingleSolution() {
    return (
      <>
        {hoverImg.isHovered && (
          <div
            className="cancel-hover"
            onMouseLeave={() => setHoverImg({ id: '', isHovered: false })}
          />
        )}
        <Container className="solution-container-wrapper fluid" fluid>
          {getLevel2?.data.description && !getSolutionLevel3 && (
            <>
              <h1 className="description-title">Description</h1>
              <Container className="">
                <p>{getLevel2?.data.description}</p>
              </Container>
            </>
          )}
          {getSolutionLevel3?.description && (
            <>
              <h1 className="description-title">Description</h1>
              <Container className="">
                <p>{getSolutionLevel3?.description}</p>
              </Container>
            </>
          )}
          {data.map((solution) => (
            <>
              {hoverImg.isHovered && hoverImg.id === solution.id && (
                <ImageOnHover
                  key={solution.id}
                  onMouseLeave={() => setHoverImg({ id: '', isHovered: false })}
                  image={API_LINK + solution.image?.data.url}
                  alternative={solution.title}
                />
              )}
              <Container className="solution-container" fluid>
                <Container className="solution-descrip fluid">
                  <Container className="img-container fluid">
                    {solution.image && (
                      <Container
                        className="fluid"
                        onMouseEnter={() =>
                          setHoverImg({ id: solution.id, isHovered: true })
                        }
                      >
                        <img
                          className="solution-image"
                          src={API_LINK + solution.image?.data.url}
                          alt={solution.title}
                        />
                      </Container>
                    )}
                    {!solution.image && (
                      <Container className="fluid">
                        <img
                          className="solution-image"
                          src={UNEXIST_IMG}
                          alt=""
                        />
                      </Container>
                    )}
                  </Container>
                  <Container className="solution-content fluid">
                    <h1>{solution.title}</h1>
                    {solution.description && (
                      <p className="description">{solution.description}</p>
                    )}
                  </Container>
                </Container>
                {((openForm.isOpen && openForm.id !== solution.id) ||
                  !openForm.isOpen) && (
                  <Container
                    className="solution-form fluid"
                    onClick={() =>
                      setOpenForm({ id: solution.id, isOpen: true })
                    }
                  >
                    <button className="doc-btn top" type="button">
                      {btn1}
                    </button>
                  </Container>
                )}

                {openForm.isOpen && openForm.id === solution.id && (
                  <Container className="solution-form top fluid">
                    <Row className="fluid">
                      <Col
                        className="fluid"
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <form
                          action="https://formspree.io/f/xoqrvezq"
                          method="post"
                        >
                          <Row className="fluid">
                            <Col
                              className="pr-15 pb-15 fluid"
                              xxl={6}
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <input
                                type="text"
                                name="nom"
                                placeholder={solutionInput1}
                                required
                              />
                            </Col>
                            <Col
                              className="pb-15 fluid"
                              xxl={6}
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <input
                                type="text"
                                name="Prénom"
                                placeholder={solutionInput2}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="fluid">
                            <Col
                              className="pr-15 pb-15 fluid"
                              xxl={6}
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                              />
                            </Col>
                            <Col
                              className="pb-15 fluid"
                              xxl={6}
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <input
                                type="text"
                                name="reference"
                                value={solution.title}
                              />
                            </Col>
                          </Row>
                          <Row className="w-max fluid">
                            <Col
                              className="pb-15 fluid"
                              xxl={12}
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <textarea name="message" placeholder="Message" />
                            </Col>
                          </Row>
                          <Row className="w-max fluid">
                            <Col
                              className="fluid"
                              xxl={12}
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <button
                                className="doc-btn float-right"
                                type="submit"
                              >
                                {btn2}
                              </button>
                            </Col>
                          </Row>
                        </form>
                        <Row className="fluid">
                          <Col
                            className="fluid"
                            xxl={12}
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Container
                              className="close-container flex float-left fluid"
                              onClick={() =>
                                setOpenForm({ isOpen: false, id: '' })
                              }
                            >
                              <img src={CLOSE_ICON} alt="close-icon" />
                              <h6>{btn3}</h6>
                            </Container>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                )}
              </Container>
            </>
          ))}
        </Container>
      </>
    );
  }
  function getPageContent() {
    if (showPageContent) {
      return (
        <Container className="single-solution-page fluid" fluid>
          <BannerSection isSolutionPage />
          <Visible lg xl xxl>
            <Container className="breadcrumb" fluid>
              <Link to="/">{titleLinkSol1}</Link>
              {(language === 'fr'
                ? location.pathname.split('/')[1]
                : location.pathname.split('/')[2]) && (
                <Link to="/solutions">{titleLinkSol2}</Link>
              )}
              {location.pathname.split('/')[2] && (
                <Link
                  to={`/solutions/${
                    language === 'fr'
                      ? location.pathname.split('/')[2]
                      : location.pathname.split('/')[3]
                  }`}
                >
                  {language === 'fr' ? `${getSolutionLevel1?.title} / ` : ' '}
                </Link>
              )}
              {location.pathname.split('/')[3] && (
                <Link
                  to={
                    language === 'fr'
                      ? `/solutions/${location.pathname.split('/')[2]}/${
                          location.pathname.split('/')[3]
                        }`
                      : `/solutions/${location.pathname.split('/')[3]}`
                  }
                >
                  {language === 'fr'
                    ? `${getSolutionLevel2?.title} / `
                    : `${getSolutionLevel1?.title} / `}
                </Link>
              )}
              {location.pathname.split('/')[4] && (
                <Link
                  to={
                    language === 'fr'
                      ? `/solutions/${location.pathname.split('/')[2]}/${
                          location.pathname.split('/')[3]
                        }/${location.pathname.split('/')[4]}`
                      : `/solutions/${location.pathname.split('/')[3]}/${
                          location.pathname.split('/')[4]
                        }`
                  }
                >
                  {language === 'fr'
                    ? `${getSolutionLevel3?.title} / `
                    : `${getSolutionLevel2?.title} / `}
                </Link>
              )}

              {location.pathname.split('/')[5] && (
                <Link
                  to={
                    language === 'fr'
                      ? `/solutions/${location.pathname.split('/')[2]}/${
                          location.pathname.split('/')[3]
                        }/${location.pathname.split('/')[4]}`
                      : `/solutions/${location.pathname.split('/')[3]}/${
                          location.pathname.split('/')[4]
                        }/${location.pathname.split('/')[5]}`
                  }
                >
                  {getSolutionLevel3?.title}
                </Link>
              )}
            </Container>
          </Visible>
          <Container className="fluid top" fluid>
            {getSingleSolution()}
          </Container>
        </Container>
      );
    }
    return <Container className="loader fluid" fluid />;
  }

  /* ******************************** RENDERING ******************************* */
  return getPageContent();
}

SingleSolution.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.objectOf(PropTypes.object).isRequired,
  getSolutionLevel1: PropTypes.objectOf(PropTypes.object).isRequired,
  getSolutionLevel2: PropTypes.objectOf(PropTypes.object).isRequired,
  getSolutionLevel3: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default SingleSolution;
