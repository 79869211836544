/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// API

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Single Solution Component                      */
/* -------------------------------------------------------------------------- */

function SingleSolution({
  image,
  alternative,
  name,
  showSolutionImage,
  solution,
  getSingleSolutionLevel3,
  key,
}) {
  /* ******************************** CONSTANTS ******************************* */
  let checkLevel = true;
  let checkLevelObject = {};
  const API_LINK = 'https://admin.luxor.com.tn';
  const [link, setLink] = useState('/');
  /* ********************************* HELPERS ******************************** */
  function getLink() {
    const checkSolutionLevel3 = getSingleSolutionLevel3.filter(
      (data) => data.level_2.data.title === solution.title,
    );
    if (checkSolutionLevel3.length === 0) {
      checkLevel = false;
    }
    checkLevelObject = checkSolutionLevel3;

    if (solution.level_1 && !solution.level_2) {
      return `/solutions/${solution.level_1.data.slug}/${solution.slug}`;
    }
    if (solution.level_2 && !solution.level_3 && checkLevel) {
      return `/solutions/${solution.level_1.data.slug}/${solution.level_2.data.slug}/${solution.slug}`;
    }
    if (solution.level_3) {
      // eslint-disable-next-line max-len
      return `/solutions/${solution.level_1.data.slug}/${solution.level_2.data.slug}/${solution.level_3.data.slug}/${solution.slug}`;
    }
    if (!checkLevelObject.title && solution.level_2) {
      return `/solutions/${solution.level_1?.data.slug}/${solution.level_2?.data.slug}/${solution.slug}`;
    }
    return `/solutions/${solution.slug}`;
  }

  useEffect(() => {
    setLink(getLink());
  }, []);
  /* ******************************** RENDERING ******************************* */
  return (
    <Link to={link}>
      <Container
        key={key}
        id={!image && 'soltion-noImage-container'}
        className={`single-solution flex flex-column justify-center text-center fluid ${
          !showSolutionImage && 'minimise-height'
        }`}
        onClick={() => {
          navigate(getLink());
        }}
      >
        {image && showSolutionImage && (
          <img src={API_LINK + image} alt={alternative} />
        )}
        <h2 className={image && 'absolute'}>{name}</h2>
      </Container>
    </Link>
  );
}

SingleSolution.propTypes = {
  key: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alternative: PropTypes.string.isRequired,
  solution: PropTypes.objectOf(PropTypes.object).isRequired,
  getSingleSolutionLevel3: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  showSolutionImage: PropTypes.bool,
};

SingleSolution.defaultProps = {
  showSolutionImage: false,
};

export default SingleSolution;
