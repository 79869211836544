/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import BannerSection from './banner';
import AllSolutions from './all-solutions';

// Assets
import COPY_ICON from '../../images/solutions/banner-section/reinforcementandrepairs.svg';
import BANNER_IMG from '../../images/solutions/banner-section/banner-image.jpg';

// Style
import './SolutionLevel.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function SolutionsLevel({
  data,
  getSingleSolutionLevel3,
  getSolutionLevel1,
  getSolutionLevel2,
  getSolutionLevel3,
  title,
  showSolutionImage,
  showBanner,
  showBannerIcon,
  location,
}) {
  /* ******************************** CONSTANTS ******************************* */
  const { language } = useI18next();
  const API = 'https://admin.luxor.com.tn';
  /* ******************************** FUNCTIONS ******************************* */
  function getBannerImage() {
    const PATH_LENGTH = location.pathname.split('/').length;
    if (
      (language === 'fr' ? PATH_LENGTH === 3 : PATH_LENGTH === 4) &&
      getSolutionLevel1?.banner
    ) {
      return API + getSolutionLevel1?.banner?.data.url;
    }
    if (
      language === 'fr'
        ? PATH_LENGTH === 4 || PATH_LENGTH === 5
        : PATH_LENGTH === 5 || PATH_LENGTH === 6
    ) {
      if (getSolutionLevel2?.banner) {
        return API + getSolutionLevel2?.banner?.data.url;
      }
      return API + getSolutionLevel1?.banner?.data.url;
    }
    return BANNER_IMG;
  }
  /* ******************************** RENDERING ******************************* */
  // LOCALIZATION
  const { t } = useTranslation();
  const { titleLinkSol1, titleLinkSol2 } = t('solution', {
    returnObjects: true,
  });

  return (
    <Container
      className={`${data.length < 3 && 'solutions-level'} fluid`}
      fluid
    >
      <BannerSection bannerImage={getBannerImage()}>
        {showBannerIcon && <img src={COPY_ICON} alt="copy-icon" />}
        {showBanner && <h1>{title}</h1>}
      </BannerSection>
      <Visible lg xl xxl>
        <Container className="breadcrumb" fluid>
          <Link to="/">{titleLinkSol1}</Link>
          {(language === 'fr'
            ? location.pathname.split('/')[1]
            : location.pathname.split('/')[2]) && (
            <Link to="/solutions">{titleLinkSol2}</Link>
          )}
          {location.pathname.split('/')[2] && (
            <Link
              to={`/solutions/${
                language === 'fr'
                  ? location.pathname.split('/')[2]
                  : location.pathname.split('/')[3]
              }`}
            >
              {language === 'fr' ? `${getSolutionLevel1?.title} / ` : ' '}
            </Link>
          )}
          {location.pathname.split('/')[3] && (
            <Link
              to={
                language === 'fr'
                  ? `/solutions/${location.pathname.split('/')[2]}/${
                      location.pathname.split('/')[3]
                    }`
                  : `/solutions/${location.pathname.split('/')[3]}`
              }
            >
              {language === 'fr'
                ? `${getSolutionLevel2?.title} / `
                : `${getSolutionLevel1?.title} / `}
            </Link>
          )}
          {location.pathname.split('/')[4] && (
            <Link
              to={
                language === 'fr'
                  ? `/solutions/${location.pathname.split('/')[2]}/${
                      location.pathname.split('/')[3]
                    }/${location.pathname.split('/')[4]}`
                  : `/solutions/${location.pathname.split('/')[3]}/${
                      location.pathname.split('/')[4]
                    }`
              }
            >
              {language === 'fr'
                ? `${getSolutionLevel3?.title} / `
                : `${getSolutionLevel2?.title} / `}
            </Link>
          )}
        </Container>
      </Visible>
      {!showBanner && (
        <Container className="description-container" fluid>
          <h1>{title}</h1>
        </Container>
      )}
      {(language === 'fr'
        ? location.pathname.split('/').length === 3
        : location.pathname.split('/').length === 4) &&
        getSolutionLevel1?.description && (
          <Container className="description-container" fluid>
            <h1>Description</h1>
            <p>{getSolutionLevel1?.description}</p>
          </Container>
        )}
      {(language === 'fr'
        ? location.pathname.split('/').length === 4
        : location.pathname.split('/').length === 5) &&
        getSolutionLevel2?.description && (
          <Container className="description-container" fluid>
            <h1>Description</h1>
            <p>{getSolutionLevel2?.description}</p>
          </Container>
        )}
      {(language === 'fr'
        ? location.pathname.split('/').length === 5
        : location.pathname.split('/').length === 6) &&
        getSolutionLevel3?.description && (
          <Container className="description-container" fluid>
            <h1>Description</h1>
            <p>{getSolutionLevel3?.description}</p>
          </Container>
        )}
      <AllSolutions
        data={data}
        showSolutionImage={showSolutionImage}
        getSingleSolutionLevel3={getSingleSolutionLevel3}
      />
    </Container>
  );
}

SolutionsLevel.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.objectOf(PropTypes.object).isRequired,
  getSingleSolutionLevel3: PropTypes.arrayOf(PropTypes.object).isRequired,
  getSolutionLevel1: PropTypes.objectOf(PropTypes.object).isRequired,
  getSolutionLevel2: PropTypes.objectOf(PropTypes.object).isRequired,
  getSolutionLevel3: PropTypes.objectOf(PropTypes.object).isRequired,
  showSolutionImage: PropTypes.bool,
  showBanner: PropTypes.bool,
  showBannerIcon: PropTypes.bool,
};

SolutionsLevel.defaultProps = {
  showSolutionImage: false,
  showBanner: false,
  showBannerIcon: false,
};

export default SolutionsLevel;
