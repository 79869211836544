/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Router as MyRouter } from '@reach/router';
import { graphql } from 'gatsby';
import axios from 'axios';

// UI local components
import { Container } from 'react-grid-system';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// Solutions all levels
import SolutionsLevel from '../page-components/page-solutions/SolutionsLevel';

// Single solution
import SingleSolution from '../page-components/page-solutions/single-solution';

// API
import { API } from '../shared/API';

import defaultImage from '../images/solutions/banner-section/banner-image.jpg';

// Styles
import '../page-styles/solutions.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function SolutionsPage({ location, pageContext }) {
  /* ******************************** CONSTANTS ******************************* */
  const SHOW_SOLUTION_CARD_IMAGE = true;
  const SHOW_BANNER_IMAGE = true;
  const SHOW_BANNER_ICON = true;
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Solutions';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    // eslint-disable-next-line max-len
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  /* ********************************** HOOKS ********************************* */

  const [getSolutionLevel1, setSolutionLevel1] = useState({});
  const [getSolutionLevel2, setSolutionLevel2] = useState({});
  const [getSolutionLevel3, setSolutionLevel3] = useState({});

  const [getSingleSolutionLevel1, setSingleSolutionLevel1] = useState([]);
  const [getSingleSolutionLevel2, setSingleSolutionLevel2] = useState([]);
  const [getSingleSolutionLevel3, setSingleSolutionLevel3] = useState([]);

  const [getSingleSolution, setSingleSolution] = useState([]);

  const [loader, setLoader] = useState(true);
  const { language } = useI18next();

  let currMetaData;
  const {
    metaData,
    metaData2,
    metaData3,
    metaDataFR,
    metaDataFR2,
    metaDataFR3,
  } = pageContext;
  if (language === 'fr') {
    currMetaData = {
      title:
        metaDataFR3?.meta_title ??
        metaDataFR2?.meta_title ??
        metaDataFR?.meta_title ??
        SITE_TITLE,
      metaDescription:
        metaDataFR3?.meta_description ??
        metaDataFR2?.meta_description ??
        metaDataFR?.meta_description ??
        SITE_DESCRIPTION,

      metaImage: metaDataFR3?.image ?? metaDataFR2?.image ?? metaDataFR?.image,
      keywords:
        metaDataFR3?.meta_keywords ??
        metaDataFR2?.meta_keywords ??
        metaDataFR?.meta_keywords,
    };
  } else {
    currMetaData = {
      title:
        metaData3?.meta_title ??
        metaData2?.meta_title ??
        metaData?.meta_title ??
        SITE_TITLE,
      metaDescription:
        metaData3?.meta_description ??
        metaData2?.meta_description ??
        metaData?.meta_description ??
        SITE_DESCRIPTION,
      metaImage: metaData3?.image ?? metaData2?.image ?? metaData?.image,
      keywords:
        metaData3?.keywords ?? metaData2?.keywords ?? metaData?.keywords,
    };
  }

  useEffect(() => {
    axios
      .get(API(language === 'en' ? 'solution_level_1_en' : 'solution_level_1'))
      .then(({ data: { data } }) => {
        const solution = data.find(({ slug }) =>
          language === 'fr'
            ? slug === location.pathname.split('/')[2]
            : slug === location.pathname.split('/')[3],
        );
        setSolutionLevel1(solution);
        setSingleSolutionLevel1(data);
        setLoader(true);
      })
      .then(() => setLoader(false));
    axios
      .get(API(language === 'en' ? 'solution_level_2_en' : 'solution_level_2'))
      .then(({ data: { data } }) => {
        const solution = data.find(({ slug }) =>
          language === 'fr'
            ? slug === location.pathname.split('/')[3]
            : slug === location.pathname.split('/')[4],
        );

        setSolutionLevel2(solution);
        setSingleSolutionLevel2(data);
        setLoader(true);
      })
      .then(() => setLoader(false));
    axios
      .get(API(language === 'en' ? 'solution_level_3_en' : 'solution_level_3'))
      .then(({ data: { data } }) => {
        const solution = data.find(({ slug }) =>
          language === 'fr'
            ? slug === location.pathname.split('/')[4]
            : slug === location.pathname.split('/')[5],
        );
        setSolutionLevel3(solution);
        setSingleSolutionLevel3(data);
      })
      .then(() => setLoader(false));
    axios
      .get(API(language === 'en' ? 'solution_en' : 'solution'))
      .then(({ data: { data } }) => {
        setLoader(true);
        const getSolutionsFromLevel2 = data.filter(({ level_2: lvl2 }) => lvl2);
        const getSolutionsFromLevel3 = data.filter(({ level_3: lvl3 }) => lvl3);
        const getSolutionsWithoutLevel3 = data.filter(
          ({ level_3: lvl3 }) => !lvl3,
        );

        const getDataFromLevel3 = getSolutionsFromLevel3?.filter(
          (lev) => lev.level_3?.data?.slug === getSolutionLevel3?.slug,
        );
        const getDataFromLevel2 = getSolutionsFromLevel2?.filter(
          (lev) => lev.level_2?.data?.slug === getSolutionLevel2?.slug,
        );

        if (getDataFromLevel3?.length > 0) {
          setSingleSolution(getDataFromLevel3);
          return 0;
        }

        if (getDataFromLevel2?.length > 0) {
          setSingleSolution(getDataFromLevel2);
          return 0;
        }

        if (getSolutionLevel3) {
          const solutions = getSolutionsWithoutLevel3.filter(
            ({ level_2: lvl2 }) =>
              lvl2?.data?.id === getSolutionLevel3.level_2?.data.id,
          );
          setSingleSolution(solutions);
          return 0;
        }

        if (getSolutionsFromLevel2) {
          const getData = getSolutionsFromLevel2?.filter(
            (lev) => lev?.level_2?.data?.slug === getSolutionLevel2?.slug,
          );
          setSingleSolution(getData);
          return 0;
        }

        if (getSolutionsWithoutLevel3) {
          const getData = getSolutionsWithoutLevel3?.filter(
            (lev) => lev.level_2?.data?.slug === getSolutionLevel2?.slug,
          );
          setSingleSolution(getData);
          return 0;
        }

        return 0;
      })
      .then(() => setLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSolutionLevel2?.slug, getSolutionLevel3?.slug, location, language]);

  /* ***************************** RENDER HELPERS ***************************** */
  function getSolutionsLevel(
    data,
    title,
    path,
    showSolutionImage,
    showBannerImage,
    showBannerIcon,
  ) {
    return (
      <SolutionsLevel
        data={data}
        title={title}
        path={path}
        showSolutionImage={showSolutionImage}
        showBanner={showBannerImage}
        showBannerIcon={showBannerIcon}
        getSingleSolutionLevel3={getSingleSolutionLevel3}
        getSolutionLevel1={getSolutionLevel1}
        getSolutionLevel2={getSolutionLevel2}
        getSolutionLevel3={getSolutionLevel3}
      />
    );
  }
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout loader={loader} location={location}>
      <Seo
        title={currMetaData?.title && currMetaData.title}
        description={
          currMetaData?.metaDescription && currMetaData.metaDescription
        }
        image={
          currMetaData?.metaImage
            ? `https://admin.luxor.com.tn${currMetaData.metaImage}`
            : defaultImage
        }
        keywords={currMetaData?.keywords && currMetaData.keywords}
      />
      {loader && <Container className="loader fluid" fluid />}
      <Container className="solutions-page fluid" fluid>
        {language === 'fr' ? (
          <MyRouter>
            {getSolutionsLevel(
              getSingleSolutionLevel1,
              'Solutions',
              '/solutions',
              SHOW_SOLUTION_CARD_IMAGE,
              SHOW_BANNER_IMAGE,
              SHOW_BANNER_ICON,
            )}

            {getSolutionsLevel(
              getSingleSolutionLevel2.filter(
                (data) => data?.level_1?.data.id === getSolutionLevel1?.id,
              ),
              getSolutionLevel1?.title,
              `/solutions/${getSolutionLevel1?.slug}`,
              SHOW_SOLUTION_CARD_IMAGE,
              SHOW_BANNER_IMAGE,
              SHOW_BANNER_ICON,
            )}
            <SingleSolution
              data={getSingleSolution}
              path={
                getSingleSolution[0]?.level_3
                  ? `/solutions/${getSolutionLevel1?.slug}/${getSolutionLevel2?.slug}/${getSolutionLevel3?.slug}`
                  : `/solutions/${getSolutionLevel1?.slug}/${getSolutionLevel2?.slug}`
              }
              getLevel2={getSingleSolution[0]?.level_2}
              getLevel3={getSingleSolution[0]?.level_3}
              getSolutionLevel1={getSolutionLevel1}
              getSolutionLevel2={getSolutionLevel2}
              getSolutionLevel3={getSolutionLevel3}
            />
            {getSolutionsLevel(
              getSingleSolutionLevel3.filter(
                (data) => data?.level_2?.data.id === getSolutionLevel2?.id,
              ),
              getSolutionLevel2?.title,
              `/solutions/${getSolutionLevel1?.slug}/${getSolutionLevel2?.slug}`,
              !SHOW_SOLUTION_CARD_IMAGE,
              SHOW_BANNER_IMAGE,
              !SHOW_BANNER_ICON,
            )}
          </MyRouter>
        ) : (
          <MyRouter>
            {getSolutionsLevel(
              getSingleSolutionLevel1,
              'Solutions',
              '/en/solutions',
              SHOW_SOLUTION_CARD_IMAGE,
              SHOW_BANNER_IMAGE,
              SHOW_BANNER_ICON,
            )}

            {getSolutionsLevel(
              getSingleSolutionLevel2.filter(
                (data) => data?.level_1?.data.id === getSolutionLevel1?.id,
              ),
              getSolutionLevel1?.title,
              `/en/solutions/${getSolutionLevel1?.slug}`,
              SHOW_SOLUTION_CARD_IMAGE,
              SHOW_BANNER_IMAGE,
              SHOW_BANNER_ICON,
            )}
            <SingleSolution
              data={getSingleSolution}
              path={
                getSingleSolution[0]?.level_3
                  ? `/en/solutions/${getSolutionLevel1?.slug}/${getSolutionLevel2?.slug}/${getSolutionLevel3?.slug}`
                  : `/en/solutions/${getSolutionLevel1?.slug}/${getSolutionLevel2?.slug}`
              }
              getLevel2={getSingleSolution[0]?.level_2}
              getLevel3={getSingleSolution[0]?.level_3}
              getSolutionLevel1={getSolutionLevel1}
              getSolutionLevel2={getSolutionLevel2}
              getSolutionLevel3={getSolutionLevel3}
            />
            {getSolutionsLevel(
              getSingleSolutionLevel3.filter(
                (data) => data?.level_2?.data.id === getSolutionLevel2?.id,
              ),
              getSolutionLevel2?.title,
              `/en/solutions/${getSolutionLevel1?.slug}/${getSolutionLevel2?.slug}`,
              !SHOW_SOLUTION_CARD_IMAGE,
              SHOW_BANNER_IMAGE,
              !SHOW_BANNER_ICON,
            )}
          </MyRouter>
        )}
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Solution", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

SolutionsPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default SolutionsPage;
