/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// UI local components
import SingleCard from '../solution-card';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  PCN Section                                 */
/* -------------------------------------------------------------------------- */

function AllSolutions({ data, showSolutionImage, getSingleSolutionLevel3 }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="all-solutions flex fluid" fluid>
      {data?.map((solution) => {
        return (
          <>
            {/* {(!solution.level_1) || (solution?.level_2?.data?.isvisible === 'value1' ||
              solution?.isvisible === 'value1') && ( */}
            <SingleCard
              key={solution.id}
              solution={solution}
              image={solution.image?.data.url}
              alternative={solution.title}
              name={solution.title}
              showSolutionImage={showSolutionImage}
              getSingleSolutionLevel3={getSingleSolutionLevel3}
            />
            {/* )} */}
          </>
        );
      })}
    </Container>
  );
}

AllSolutions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  getSingleSolutionLevel3: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSolutionImage: PropTypes.bool,
};

AllSolutions.defaultProps = {
  showSolutionImage: false,
};

export default AllSolutions;
